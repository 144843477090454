import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { UserRole, ApprovalStatus, ExternalInvoicingSystems, InvoiceDueInterval } from '../shared/enums';
import { UserCompanyRole } from '@cartwheel/web-components';
import { TimeEntry } from 'app/models/timeentry';
import { CartwheelSelectOptions } from '@cartwheel/web-components';
import { PATTERNS } from './consts';
import { HttpResponse } from '@angular/common/http';
import * as fileSaver from 'file-saver';

dayjs.extend(isBetween);


export function IsAdmin(roles: UserCompanyRole[], companyId: string = null) {
  const company = roles.find(r => r.companyId === companyId);

  if (!company) {
    return false;
    // should go to unauthorized page
  }

  return (
    company.userRole === UserRole.Admin ||
    company.userRole === UserRole.Manager ||
    company.userRole === UserRole.SuperAdmin
  );
}

export function checkIfNumber(event: KeyboardEvent) {
  if (event.key === 'Tab' || event.key === 'Delete' || event.key === 'Backspace' || event.key === '.'
    || event.key === 'LeftArrow' || event.key === 'RightArrow') {
    return true;
  } else if (!PATTERNS.Decimal.test(event.key)) {
    event.preventDefault();
  }
}

export function saveFileFromBlobResponse(file: HttpResponse<Blob>): void {
  const filename = file.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/['"]+/g, '')
  fileSaver.saveAs(file.body, filename);
}

export function filterCurrentWeekTasks(
  dates: [Date, Date],
  entries: Array<TimeEntry>
) {
  const results = entries && entries.length > 0 ?
    entries.filter(entry => {
      return dayjs(entry.startTime).isBetween(dates[0], dates[1], null, '[]');
    }) : [];
  return results;
}


export function getStatus(entries: TimeEntry[]) {
  if (entries.every(e => e.approvalStatus === ApprovalStatus.Denied)) {
    return ApprovalStatus.Denied;
  }

  if (entries.every(e => e.approvalStatus === ApprovalStatus.Unsent)) {
    return ApprovalStatus.Unsent;
  }

  if (entries.every(e => e.approvalStatus === ApprovalStatus.Pending)) {
    return ApprovalStatus.Pending;
  }

  if (entries.every(e => e.approvalStatus === ApprovalStatus.Approved)) {
    return ApprovalStatus.Approved;
  }

  return ApprovalStatus.Invalid;
}

export function getInvoiceProviderData(isMobile: boolean = false) {
  return {
    // [ExternalInvoicingSystems.Veem]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.Veem],
    //   imageSrc: (isMobile) ? 'assets/veem-logo-mobile.png' : 'assets/veem-logo.png'
    // },
    [ExternalInvoicingSystems.QuickBooks]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.QuickBooks],
      imageSrc: (isMobile) ? 'assets/quick-books-logo-mobile.png' : 'assets/quick-books-logo.png'
    },
    [ExternalInvoicingSystems.Cartwheel]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.Cartwheel],
      imageSrc: (isMobile) ? 'assets/group-2.png' : 'assets/group-2@2x.png'
    },
    // [ExternalInvoicingSystems.FreshBooks]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.FreshBooks],
    //   imageSrc: (isMobile) ? 'assets/fresh-books-logo-mobile.png' : 'assets/fresh-books-logo.png'
    // },
    // [ExternalInvoicingSystems.Xero]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.Xero],
    //   imageSrc: (isMobile) ? 'assets/xero-logo-mobile.png' : 'assets/xero-logo.png'
    // },
    // [ExternalInvoicingSystems.Qwil]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.Qwil],
    //   imageSrc: 'assets/qwil-logo.svg'
    // },
    // [ExternalInvoicingSystems.BillCom]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.BillCom],
    //   imageSrc: 'assets/billcom.svg'
    // }
  }
}
export function getInvoiceSourceData(isMobile: boolean = false) {
  return {
    [ExternalInvoicingSystems.Paidiem]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.Paidiem],
      imageSrc: (isMobile) ? 'assets/paidiem-logo.png' : 'assets/paidiem-logo.png'
    },
    [ExternalInvoicingSystems.SageIntacct]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.SageIntacct],
      imageSrc: (isMobile) ? 'assets/saga-logo.png' : 'assets/saga-logo.png'
    },
  }
}


export function sortInvoiceDueDate(): CartwheelSelectOptions<number> {
  return Object.entries(InvoiceDueInterval).sort((first, second) => first[1] > second[1] ? 1 : -1).map(([value, label], index) => ({
    value: index,
    label
  })) as CartwheelSelectOptions<number>;
}

export function fuzzyMatch(primaryValue: string, listValue: string, matchPercentage: number = 80): boolean {
  // let failedMatch = 0;
  if (!primaryValue || primaryValue === '') {
    return false
  }
  const primaryArray = primaryValue.toLowerCase().replace(/\s/g, '').split('').sort();
  const listArray = listValue.toLowerCase().replace(/\s/g, '').split('').sort();
  const primaryLength = primaryArray.length, listLength = listArray.length;

  if (primaryLength !== listLength) {
    // failedMatch = Math.abs(primaryLength - listLength);
    return false;
  }

  for (let i = 0; i < primaryLength; i++) {
    if (primaryArray[i] !== listArray[i]) {
      return false;
    }
  }
  // if (failedMatch === 0 || (primaryLength / failedMatch) * 100 >= matchPercentage) {
  //   return true;
  // }
  return true;

}
